@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.address-list-card {
  background-color: $white-900;
  border: 1px solid $gray-50;
  border-radius: 3px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.13);
  padding: 20px 20px 30px;
  width: 100%;

  &--item {
    margin: 5px 0 20px;
    padding: 0 0 0 35px;
    width: 100%;

    .icon {
      @include rem('font-size', 20px);
      color: $gray-10;
      left: 0;
      position: absolute;
      top: 0;
    }

    .text {
      @include font($font-default, 14px, 400, $gray-600);
      line-height: 1.4em;
      width: 100%;
      white-space: pre-line;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .title {
    @include font($font-default, 20px, 600, $blue-950);
    margin: 0 0 20px;
    width: 100%;
  }
}