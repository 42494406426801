@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-news-item {
  border: 1px solid $gray-400;
  float: left;
  width: 100%;

  &--date,
  &--image,
  &--info {
    float: left;
    width: 100%;
  }

  &--date {
    align-items: center;
    background-color: $white-900;
    display: inline-flex;
    height: 45px;
    padding: 0 20px;
    flex-flow: row wrap;
    justify-content: center;
    text-align: right;

    .icon {
      color: $blue-700;
      left: 20px;
      position: absolute;
      top: 50%;
      text-decoration: none;
      transform: translate(0, -50%);
    }

    .paragraph {
      @include font($font-default, 13px, 700, $gray-700);
      line-height: 1em;
      margin: 0;
      width: 100%;
    }
  }

  &--image {
    height: 175px;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &--info {
    background-color: $white-900;
    height: calc(180px + 30px);
    padding: 30px 25px;
    text-decoration: none;
    text-align: left;
    
    .description {
      color: $white-900;
      float: left;
      margin: 0;
      text-align: left;
      width: 100%;
    }

    .description {
      @include font($font-default, 14px, 400, $gray-700);
      line-height: 1.4em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;

      > p {
        margin-top: 0;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .list-news-item {
      &--info {
        height: auto;
      }
    }
  }
}