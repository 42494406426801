@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.form-contact {
  background-color: $white-900;
  border: 1px solid $gray-50;
  box-sizing: border-box;
  border-radius: 3px;
  max-width: 385px;
  padding: 30px 25px;
  width: 100%;

  &--form,
  &--footer,
  &--title {
    width: 100%;
  }

  &--form {
    padding: 40px 0 0;

    &--group {
      width: 100%;
    }

    .check-text {
      @include font($font-default, 13px, 400, $gray-700);
      margin: 0;
      padding: 10px;
      width: calc(100% - 20px);

      a {
        color: $blue-950;
        font-weight: 600;
        text-decoration: none;
      }
    }
  }

  &--footer {
    padding: 20px 0 0;
    text-align: right;
    width: 100%;

    .btn {
      @include font($font-default, 13px, 500, $white-900);
      background-color: $blue-950;
      border-radius: 3px;
      padding: 15px 30px;
      text-transform: uppercase;

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  &--title {
    padding: 0 0 0 45px;

    .btn {
      right: 15px;
      position: absolute;
      
      .icon {
        @include rem('font-size', 29px);
        color: $gray-10;
      }
    }

    .icon {
      @include rem('font-size', 37px);
      color: $blue-950;
      left: 0;
      position: absolute;
    }

    .subtitle,
    .title {
      margin: 0;
      width: 100%;
    }

    .subtitle {
      @include font($font-default, 13px, 400, $gray-700);
      border: none;
      padding: 0;
    }

    .title {
      @include font($font-default, 24px, 600, $black-800);
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    max-width: initial;
    margin: 0 0 60px;
    padding: 20px 15px;

    .form-contact {
      &--form {
        display: none;
      }

      &--title {
        .subtitle {
          display: none;
        }
      }
    }

    &[data-open="true"] {
      z-index: 99;

      .form-contact {
        &--form {
          display: inline-block;
        }

        &--title {
          .btn {
            .icon {
              transform: rotate(90deg) translate(0, 10px);
            }
          }

          .subtitle {
            display: inline-block;
          }
        }
      }
    }
  }
}