.tabs-item-modal {
  align-items: center;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  left: 0;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  > div {
    max-height: calc(100% - 40px);
    max-width: calc(100% - 40px);

    img {
      box-shadow: 0 10px 25px rgb(0 0 0 / 50%);
      border-radius: 10px;
      object-fit: cover;
      width: 100%;
    }
  }

}