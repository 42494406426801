@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.header-menu-list {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  flex-flow: row wrap;
  height: 85px;
  list-style: none;
  margin: 0;
  padding: 0;

  &--item {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
    height: 100%;
    
    .link {
      @include font($font-default, 16px, 600, $color_text);
      background-color: $white-900;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      flex-flow: row wrap;
      height: 100%;
      line-height: 1em;
      padding: 0 20px;
      text-decoration: none;
      text-transform: uppercase;
      z-index: 2;

      &:before {
        border-left: 17px solid transparent;
        border-right: 17px solid transparent;
        border-top: 0px solid $gray-300;
        content: '';
        height: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);
        top: calc(100% - 5px);
        width: 0;
      }

      &:hover,
      &.active {
        background-color: $gray-300;

        &:before {
          border-top: 17px solid $gray-300;
        }
      }
    }

    &:hover {
      .link {
        &+div {
          display: inline-block;
        }
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    background-color: $white-900;
    display: none;
    height: calc(100vh - 100px);
    left: 0;
    max-width: 375px;
    padding: 20px 0;
    position: fixed;
    top: 100px;
    width: 100%;

    .header-menu-list {
      &--item {
        display: inline-block;
        height: auto;
        margin: 0 0 20px;
        text-align: left;
        width: 100%;

        .link {
          @include rem('font-size', 21px);
          background-color: transparent;
          display: inline-block;
          width: 100%;

          &:before {
            display: none;
          }

          &.active {
            color: $blue-900;
          }
        }
      }
    }

    & + div {
      display: none;
    }

    &[data-open="false"] {
      display: inline-block;
    }
  }
}