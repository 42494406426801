@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.pagination-footer {
  display: inline-flex;
  align-content: center;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 40px 0 0;
  text-align: center;
  width: 100%;

  &--item {
    vertical-align: top;
  }

  &--list {
    background-color: $white-900;
    border: 1px solid $gray-50;
    border-radius: 3px;
    float: none;
    margin: 0;
    padding: 0 5px;
  }
}