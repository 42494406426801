@import '../../../styles/settings/colors';

.select {
  float: left;
  pointer-events: auto;
  position: relative;
  vertical-align: top;
  width: 100%;
  z-index: 1;

  &--list {
    background-color: $white-900;
    border: 1px solid $gray-10;
    border-radius: 0 0 5px 5px;
    display: none;
    left: 0;
    max-height: 300px;
    margin: 0;
    overflow: hidden;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    vertical-align: top;
    z-index: 1;

    &--item {
      float: left;
      position: relative;
      width: 100%;
      vertical-align: top;
    }

    &:before {
      background-color: $white-900;
      border-radius: 0;
      content: '';
      height: 8px;
      left: 0;
      position: absolute;
      top: 100%;
      width: 100%;
    }
  }

  &[data-active="true"] {
    z-index: 99999;

    .select {
      &--list {
        display: inline-block;
      }
    }

    > button {
      border-radius: 5px 5px 0 0;
      border-bottom-color: transparent;
    }
  }
}