@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.highlights-item {
  width: 100%;

  &--image {
    height: 250px;
    object-fit: cover;
    width: 100%;
  }

  &--link {
    background-color: $green-400;
    bottom: 20px;
    left: 50%;
    padding: 20px;
    position: absolute;
    transform: translate(-50%, 0);
    text-align: center;
    text-decoration: none;
    width: 270px;

    .text {
      @include font($font-default, 16px, 600, $blue-950);
      text-transform: uppercase;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    display: inline-flex;
    height: 100%;

    .highlights-item {
      &--image {
        display: none;
      }

      &--link {
        align-items: center;
        align-content: center;
        background-color: $green-400;
        bottom: initial;
        display: inline-flex;
        flex-flow: row wrap;
        justify-content: center;
        left: initial;
        position: relative;
        transform: none;
        width: 100%;
        
        .text {
          @include rem('font-size', 20px);
          color: $white-900;
        }
      }
    }
  }
}