@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-grid-title {
  border: 1px solid $gray-300;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  padding: 25px;
  width: 100%;

  .icon {
    @include rem('font-size', 31px);
    color: $blue-950;
    left: 25px;
    position: absolute;
    top: 20px;
  }

  .name {
    @include font($font-default, 32px, 400, $blue-950);
    text-decoration: none;
    width: 100%;
  }

  .title {
    @include font($font-default, 20px, 600, $blue-950);
    margin: 0 0 10px;
    padding: 0 0 0 40px;
    width: 100%;
  }
}