.news-content {
  float: left;
  padding: 0 0 40px;
  width: 100%;

  &--container {
    float: left;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px 0;
    margin: 0;
    width: 100%;
  }

  .container-element {
    text-align: center;
  }
}