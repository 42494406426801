@import '../../styles/settings/colors';

.header {
  background-color: $white-900;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    height: 100px;
    position: fixed;
  }
}