@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.select-item-button {
  background-color: $white-900;
  border: none;
  border-bottom: 1px solid $gray-10;
  float: left;
  height: 38px;
  line-height: 1em;
  padding: 5px 20px;
  position: relative;
  text-align: left;
  width: 100%;
  user-select: none;
  vertical-align: top;

  .text {
    @include font($font-default, 16px, 400, $blue-950);
    margin: 0;
    line-height: 1em;
    transition: all 400ms ease-in;
    width: 100%;
    white-space: nowrap;
  }

  &[data-active="true"] {
    .text {
      color: $blue-950;
    }

    &:before {
      background-color: $blue-950;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0.2;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &:hover {
    .text {
      color: $blue-950;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}