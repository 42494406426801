@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.text-component {
  @include font($font-default, 17px, 400, $gray-600);
  line-height: 1.5em;
  margin: 0 0 20px;
  white-space: pre-line;
  width: 100%;

  &[data-type="2"] {
    margin-top: 60px;
  }
}