.highlights {
  margin: 40px 0 0;
  width: 100%;

  &--item {
    padding: 1px;
    width: calc(100% / 3);
  }

  &--list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .highlights {
      &--item {
        padding: 0 8px;
        width: 100%;
      }

      &--list {
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 1fr; 
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    margin: 40px 0 60px;

    .highlights {
      &--item {
        margin: 10px 0;
      }

      &--list {
        display: inline-block;
        padding: 0 90px;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .highlights {
      &--list {
        padding: 0 40px;
      }
    }
  }
}