.webdoor {
  height: 325px;
  margin: 85px 0 0;
  pointer-events: auto;
  width: 100vw;

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    height: 300px;
    margin: 100px 0 0;
  }
}