@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.tabs-body {
  width: 100%;

  &--item,
  &--list {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &--item {
    display: none;
  }

  &--item {
    &[data-active="true"] {
      display: inline-block;
    }
  }
}