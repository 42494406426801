@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.header-submenu {
  background-color: $gray-400;
  display: none;
  left: 0;
  min-width: 200px;
  padding: 20px 0;
  position: absolute;
  top: 100%;
  width: auto;
  z-index: 0;

  &--item {
    padding: 10px 15px;
    width: 100%;

    .link-submenu {
      @include font($font-default, 14px, 700, $gray-700);
      text-decoration: none;
      text-transform: none;
    }
  }

  &--list {
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
  }

  &:hover {
    display: inline-block;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    background-color: transparent;
    display: inline-block;
    left: initial;
    padding: 10px 25px 0;
    position: relative;
    top: initial;

    &--item {
      border: none;
      border-bottom: 1px dashed $gray-300;
      padding: 15px 0;

      .link-submenu {
        font-weight: 300;
        padding: 0;
        text-transform: uppercase;
      }
    }

    &--list {
      padding: 0 25px;
    }
  }
}