.address-list-item {
  margin: 0 0 30px;
  padding: 0;
  width: 100%;

  &--list {
    column-count: 2;
    column-gap: 20px 20px;
    margin: 40px 0 0;
    list-style: none;
    padding: 0;
    width: 100%;

    &--item {
      margin: 0 0 20px;
      width: 100%;
    }
  }

  .subtitle {
    margin: 0;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .address-list-item {
      &--list {
        column-count: initial;
        column-gap: initial;
      }
    }
  }
}