@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.tabs-filter {
  float: right;
  width: 190px;

  .label {
    @include font($font-default, 16px, 600, $color-text);
    margin: 0 0 5px;
    text-transform: uppercase;
    width: 100%;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    margin: 20px 0;
    width: 100%;
  }
}