@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-grid-file {
  width: 100%;

  &--list {
    column-count: 2;
    column-gap: 30px;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &--item {
    margin: 0 0 25px;
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .list-grid-file {
      &--list {
        column-count: 1;
      }
    }
  }
}