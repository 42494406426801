@import '../../../styles/settings/settings';

@import '../../../styles/tools/mixins';

.gallery-videos-controls {
  left: 50%;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  top: calc(50% + 70px);
  width: 965px;
  z-index: 4;

  .btn {
    background-color: $green-400;
    border: none;
    height: 85px;
    outline: none;
    float: left;
    width: 40px;
    
    .icon {
      @include rem('font-size', 15px);
      color: $white-900;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.next {
      float: right;
      margin-left: 10px;
    }

    &.prev {
      margin-right: 10px;

      .icon {
        transform: translate(-50%, -50%) rotate(-180deg);
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    background-color: transparent;
    bottom: 0;
    left: 0;
    height: 100%;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 1;

    .btn {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);

      &.next {
        right: 0;
      }

      &.prev {
        left: 0;
      }
    }
  }
}