.summary {
  margin: 30px 0;
  width: 100%;

  &--container {
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .summary {
      &--container {
        width: calc(100vw - 40px);
      }
    }
  }
}