@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.header-button-mobile {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  display: none;
  height: 45px;
  outline: none;
  right: 0px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 55px;

  .icon {
    @include rem('font-size', 20px);
    color: $blue-950;
    display: inline-block;
    left: 50%;
    position: absolute;
    top: calc(50% - 1px);
    transform: translate(-50%, -50%);

    &:last-child {
      @include rem('font-size', 19px);
      display: none;
    }
  }

  &[data-open="true"] {
    background-color: $blue-950;

    .icon {
      display: none;

      &:last-child {
        color: $white-900;
        display: inline-block;
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    display: inline-block;
  }
}