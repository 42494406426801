@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.news-item {
  background-color: $white-900;
  border: 1px solid $gray-50;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  float: left;
  padding: 25px 30px;
  width: 100%;

  &--content {
    padding: 0 0 0 20px;
    width: calc(100% - 195px);
  }

  &--image {
    float: left;
    height: 120px;
    width: 195px;

    img {
      float: left;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    padding: 25px 20px;
    
    .news-item {
      &--content {
        margin: 20px 0 0;
        padding: 0;
        width: 100%;
      }

      &--image {
        width: 100%;
      }
    }
  }
}