.infographic {
  margin: 60px 0 30px;
  width: 100%;

  &--content {
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 640px) {
    margin: 40px 0 30px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    margin: 0;
  }
}