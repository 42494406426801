@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-block-image {
  width: 100%;

  &--item {
    width: 100%;

    &--link {
      width: 100%;
      
      .image {
        width: 100%;
      }
  
      .text {
        @include font($font-default, 20px, 500, $blue-950);
        background-color: $green-400;
        bottom: 0;
        left: 0;
        max-width: 220px;
        padding: 10px 20px;
        position: absolute;
        text-align: center;
        text-decoration: none;
        width: 100%;
      }
    }
  }

  &--list {
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 50px;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .list-block-image {
      &--list {
        grid-template-columns: 1fr; 
      }
    }
  }
}