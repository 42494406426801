@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.text-component {
  @include font($font-default, 17px, 400, $gray-600);
  line-height: 1.5em;
  margin: 0 0 20px;
  white-space: pre-line;
  width: 100%;

  ul {
    margin: 10px 0 0;
    padding: 0 0 0 10px;
    width: 100%;

    li {
      float: left;
      width: 100%;

      &:before {
        background-color: $gray-600;
        border-radius: 0;
        content: '';
        height: 2px;
        left: -7px;
        position: absolute;
        top: 12px;
        width: 1px;
      }
    }
  }
}