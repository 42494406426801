@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.banner {
  margin: 40px 0;
  width: 100%;

  &--description,
  &--info {
    width: calc(50% - 10px);
  }

  &--description {
    background-color: $green-400;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.13);
    border-radius: 3px;
    margin: 0 10px 0 0;
    padding: 45px 60px;

    .text {
      @include font($font-default, 28px, 400, $blue-950);
      margin: 0;
    }
  }

  &--container {
    width: 100%;
  }

  &--info {
    background-color: $white-900;
    border: 1px solid $gray-50;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.13);
    border-radius: 3px;
    margin: 0 0 0 10px;
    padding: 40px 35px;

    &--title {
      float: right;
      width: calc(100% - 80px);

      .small,
      .text,
      .title {
        margin: 0;
        line-height: 1em;
        width: 100%;
      }

      .small,
      .text {
        @include font($font-default, 20px, 400, $gray-700);
        line-height: 1.4em;
      }

      .text {
        font-weight: 600;
      }

      .title {
        @include font($font-default, 32px, 400, $blue-950);
        margin: 40px 0;
      }
    }

    .icon {
      @include rem('font-size', 55px);
      color: $blue-950;
      left: 35px;
      position: absolute;
      top: 40px;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    .banner {
      &--description {
        .text {
          @include rem('font-size', 22px);
        }
      }

      &--info {
        &--title {
          margin: 20px 0 0;
          width: 100%;

          .small,
          .text {
            @include rem('font-size', 22px);
          }

          .title {
            @include rem('font-size', 20px);
            margin: 30px 0;
          }
        }

        .icon {
          left: initial;
          position: relative;
          top: initial;
        }
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    .banner {
      &--description,
      &--info {
        margin: 15px 0;
        width: 100%;
      }
    }
  }
}