@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.new-details {
  margin: 0 0 40px;
  width: 100%;

  &--container {
    margin: 0;
    width: 100%;

    .description {
      @include font($font-default, 17px, 400, $gray-600);
      display: inline;
      line-height: 1.5em;
      white-space: pre-line;
    }

    img {
      float: left;
      margin: 0 20px 10px 0;
    }
  }

  &--title {
    @include font($font-default, 26px, 600, $black-900);
    border: none;
    border-bottom: 1px solid $gray-50;
    margin: 60px 0 40px;
    padding: 0 0 10px;
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .content {
      padding: 0;
    }

    .new-details {
      &--container {

        img {
          margin: 0 0 40px;
          width: 100%;
        }
      }
    }
  }
}