@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.list-news {
  float: left;
  margin: 20px 0;
  width: 100%;

  .slider {
    display: grid;
    margin: 0;
    width: 100%;

    .slick {
      &-list,
      &-track { 
        touch-action: pan-y;
      }
  
      &-list {
        float: left;
        padding: 0;
        overflow: hidden;
        width: 100%;
      }
  
      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;
        
        > div {
          width: 100%;
        }
      }
  
      &-slider {
        display: grid;
        float: left;
        width: 100%;
      }
  
      &-track {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;
        gap: 20px;
        float: left;
        width: 100%;
        vertical-align: top;
      }
    }
  }

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1240px) {
    .slider {
      padding: 0 90px;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .slider {
      padding: 0 60px;

      .slick {
        &-dots {
          bottom: -40px;
          text-align: center;
          width: 100%;

          > li {
            float: none;
          }
        }
      }
    }
  }
}