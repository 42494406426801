.address-list {
  width: 100%;

  &--container {
    margin: 30px 0;
    padding: 0;
    width: 100%;

    &--list {
      padding: 0 0 0 25px;
      width: calc(100% - 385px);
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    > .content {
      padding: 0;
    }
    
    .address-list {
      &--container {
        &--list {
          padding: 0;
          width: 100%;
        }
      }
    }
  }
}