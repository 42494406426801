@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.webdoor-internal {
  background-color: $gray-100;
  margin: 85px 0 0;
  width: 100%;

  &--bg {
    width: 100%;

    .image {
      float: left;
      width: 100%;
    }

    &:before {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 29.48%, rgba(0, 0, 0, 0.67) 100%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }

  &--info {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    z-index: 3;

    &--container {
      z-index: 2;

      .subtitle,
      .title {
        float: left;
        margin: 0;
        width: 100%;
      }

      .subtitle {
        @include font($font-default, 24px, 400, $white-900);
        border: none;
        padding: 0;
      }

      .title {
        @include font($font-default, 50px, 500, $white-900);
        line-height: 1em;
        text-transform: uppercase;
        white-space: pre-line;
      }
    }
  }

  &[data-type="2"] {
    .webdoor-internal {
      &--bg {
        display: flex;
        max-width: 1200px;
        margin: 0 auto;
      }

      &--info {
        &--container {
          padding: 0 60px;
        }
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    margin: 100px 0 0;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    background-color: $green-400;

    .webdoor-internal {
      &--bg {
        display: none;
      }

      &--info {
        left: initial;
        padding: 30px 0;
        position: relative;
        top: initial;
        transform: none;

        &--container {
          .subtitle,
          .title {
            color: $blue-950;
          }
        }
      }
    }

    &[data-type="2"] {
      .webdoor-internal {
        &--bg {
          display: none;
        }

        &--info {
          &--container {
            padding: 0;
          }
        }
      }
    }
  }
}