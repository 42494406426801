.reports {
  margin: 0 0 30px;
  width: 100%;

  &--container {
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .reports {
      &--container {
        width: calc(100vw - 40px);
      }
    }
  }
}