@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-icons {
  margin: 0 0 20px;
  width: 100%;

  &--list,
  &--title {
    width: 100%;
  }

  &--list {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 30px; 
    margin: 0;
    padding: 0;

    &--item {
      border: 1px solid $gray-400;
      height: 100px;
      margin: 0;
      overflow: hidden;
      max-width: 175px;

      .link {
        height: 100%;
        text-decoration: none;
        width: 100%;
      }

      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }
  }

  &--title {
    @include font($font-default, 22px, 400, $gray-600);
    border: none;
    border-bottom: 1px solid $gray-400;
    padding: 0 0 10px;
  }

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1200px) {
    .list-icons {
      &--list {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .list-icons {
      &--list {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .list-icons {
      &--list {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}