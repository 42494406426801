@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.title {
  text-align: left;
  width: 100%;

  .text {
    @include font($font-default, 30px, 600, $blue-950);
    text-transform: uppercase;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .text {
      @include rem('font-size', 25px);
    }
  }
}