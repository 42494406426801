@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.select-title {
  background-color: $white-900;
  border: 1px solid $gray-50;
  border-radius: 5px;
  height: 36px;
  float: left;
  padding: 0;
  width: 100%;
  vertical-align: top;
  z-index: 2;

  &--title {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    float: left;
    height: 100%;
    margin: 0;
    padding: 0 20px 0 30px;
    width: 100%;
    user-select: none;
    vertical-align: top;

    .icon,
    .text {
      float: left;
      position: relative;
    }

    .icon {
      @include rem('font-size', 11px);
      color: $blue-700;
      left: 10px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%) rotate(90deg);
    }

    .text {
      @include font($font-default, 16px, 600, $blue-950);
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  &[data-active="true"] {
    .select-title {
      &--title {
        .icon {
          transform: translate(0, calc(-50% - 1px)) rotate(-90deg);
        }
      }
    }
  }
}