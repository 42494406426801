@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.news-list {
  padding: 0 30px 0 0;
  width: 50%;

  &--list {
    list-style: none;
    margin: 30px 0 0;
    padding: 0;
    width: 100%;

    &--item {
      border: none;
      border-bottom: 1px solid $gray-300;
      margin: 0 0 30px;
      padding: 0 0 30px;
      width: 100%;
    }
  }

  > .subtitle {
    margin: 0;
    padding: 0 0 10px;

    .text {
      @include rem('font-size', 30px);
    }
  }

  .link {
    @include font($font-default, 14px, 600, $blue-950);
    float: right;
    text-transform: uppercase;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    width: 100%;
  }
}