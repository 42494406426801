@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-grid-links {
  margin: 40px 0;
  width: 100%;

  &--list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &--item {
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .list-grid-links {
      &--list {
        display: inline-block;
      }

      &--item {
        margin: 0 0 30px;
      }
    }
  }
}