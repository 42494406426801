@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.subtitle {
  border: none;
  border-bottom: 3px solid $blue-950;
  float: left;
  margin: 20px 0 30px;
  padding: 20px 0;
  text-align: left;
  width: 100%;

  .text {
    @include font($font-default, 25px, 700, $blue-950);
    margin: 0;
    text-transform: uppercase;
  }
}