@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-grid-collapse-item {
  background-color: $white-900;
  border: 1px solid $gray-50;
  border-radius: 3px;
  break-inside: avoid;
  display: grid;
  grid-template-rows: 1fr auto;
  margin: 0;
  margin-bottom: 25px;
  padding: 20px 20px 20px 50px;

  &--header {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    width: 100%;
    
    .icon {
      @include rem('font-size', 18px);
      color: $blue-950;
      left: -30px;
      position: absolute;
      top: 0px;
    }
  }

  .description,
  .title {
    margin: 0;
    width: 100%;
  }

  .description {
    @include font($font-default, 15px, 400, $gray-600);
    display: none;
    margin: 0;
    line-height: 1.6em;
    white-space: pre-line;
  }

  .title {
    @include font($font-default, 18px, 600, $blue-950);
    cursor: pointer;
    line-height: 1.3em;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  &[data-active="true"] {
    background-color: rgba($gray-50, .2);

    .list-grid-collapse-item {
      &--header {
        .icon {
          transform: rotate(90deg);
        }
      }
    }

    .description {
      display: inline-block;
      margin: 20px 0 0;
    }
  }
}