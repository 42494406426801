@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-numbers {
  margin: 30px 0;
  text-align: center;
  width: 100%;

  &--list {
    list-style: none;
    margin: 0;
    gap: 25px;
    max-width: 800px;
    column-count: 2;
    width: 100%;
  }

  &--item {
    @include font($font-default, 17px, 400, $gray-600);
    line-height: 1.6em;
    padding: 0 0 0 65px;
    text-align: left;
    width: 100%;

    .number {
      background-color: $green-400;
      border-radius: 100%;
      content: attr(data-number);
      height: 43px;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: 43px;
      
      &-text {
        @include font($font-default, 22px, 500, $blue-900);
        font-style: italic;
        line-height: 1em;
        left: 50%;
        position: absolute;
        top: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }
    
    .text {
      width: 100%;
    }
  }
}