@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-grid {
  width: 100%;

  img {
    max-width: 100%;
    display: block;
  }

  &--item {
    background-color: $white-900;
    border: 1px solid $gray-50;
    border-radius: 3px;
    break-inside: avoid;
    display: grid;
    grid-template-rows: 1fr auto;
    margin: 0;
    margin-bottom: 10px;
    padding: 20px 20px 20px 75px;

    .icon {
      @include rem('font-size', 27px);
      color: $blue-950;
      left: 25px;
      position: absolute;
      top: 20px;
    }
    
    .description,
    .title {
      margin: 0;
      width: 100%;
    }

    .description {
      @include font($font-default, 15px, 400, $gray-600);
      line-height: 1.6em;
      white-space: pre-line;
    }

    .title {
      @include font($font-default, 18px, 600, $blue-950);
      margin: 0 0 5px;
      padding: 0;
      text-transform: uppercase;
    }

    img {
      margin: 20px 0 0;
      width: 100%;
    }
  }
  
  &--container {
    column-count: 2;
    column-gap: 10px;
    width: 100%;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .list-grid {
      &--container {
        column-count: 1;
      }
    }
  }
}