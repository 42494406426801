@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.news {
  float: left;
  width: 100%;

  &--loader {
    display: none;
    float: left;
    padding: 190px 0;
    width: 100%;

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 2em;
      height: 2em;
    }

    .loader {
      @include rem('font-size', 12px);
      animation: spinner-loader 1.1s infinite linear;
      border-top: 0.2em solid $green-400;
      border-right: 0.2em solid $green-400;
      border-bottom: 0.2em solid $green-400;
      border-left: 0.2em solid $white-900;
      margin: 0;
      left: calc(50% - 14px);
      position: absolute;
      text-indent: -9999em;
      top: calc(50% - 15px);
      transform: translateZ(0);
      z-index: 1;
    }

    &[data-active="true"] {
      display: inline-block;
    }
  }

  .container-element {
    padding: 0;
  }

  &[data-loading="true"] {
    .container-element {
      display: none;
    }

    div[data-type="loader"] {
      display: inline-block;
    }
  }
}
