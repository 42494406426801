.video-player {
  width: 100%;

  > div {
    height: 0;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    width: 100% !important;

    video {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}