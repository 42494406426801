@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.tabs-header {
  width: 100%;

  &--item {
    width: auto;

    .btn {
      @include font($font-default, 14px, 400, $gray-10);
      background-color: transparent;
      padding: 20px 0;
      text-align: center;
      text-transform: uppercase;
      width: 130px;

      &:before,
      &:after {
        content: '';
        position: absolute;
      }

      &:before {
        background-color: $gray-10;
        height: 3px;
        left: 1px;
        top: 0;
        width: 100%;
      }

      
      &:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $gray-10;
        height: 0;
        left: 50%;
        transform: translate(-50%, 0);
        top: 0;
        width: 0;
      }

      &[data-active="true"] {
        color: $blue-950;

        &:before {
          background-color: $blue-950;
        }

        &:after {
          border-top-color: $blue-950;
        }
      }
    }

    &:before {
      background-color: transparent;
      border: 1px solid $gray-300;
      border-bottom: none;
      border-right: none;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: -1px;
      width: 100%;
    }

    &:last-child {
      &:before {
        border-right: 1px solid $gray-300;
      }
    }
  }

  &--list {
    margin: 0;
    list-style: none;
    padding: 0;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .tabs-header {
      &--list {
        width: 100%;
      }
    }
  }
}