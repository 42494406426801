@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-grid-icon {
  margin: 20px 0 60px;
  width: 100%;

  &--list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &--item {
    width: 100%;

    &:before {
      background-color: $gray-400;
      content: '';
      height: 195px;
      right: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 1px;
    }

    &:last-child {
      &:before {
        display: none;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .list-grid-icon {
      &--list {
        display: inline-block;
      }

      &--item {
        margin-bottom: 40px;
      }
    }
  }
}