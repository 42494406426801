@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.pagination-button {
  background: transparent;
  border: none;
  color: $gray-10;
  cursor: pointer;
  margin: 0;
  line-height: 1em;
  padding: 10px;
  width: 100%;

  &[data-active="true"] {
    color: $blue-950;
  }
}