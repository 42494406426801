/* --- ANIMATIONS --- */

$base-duration: 600ms;
$base-ease: cubic-bezier(0.250, 0.460, 0.450, 0.940);

/* --- ANIMATION --- */
@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg) translate(0, -50%);
    transform-origin: top;
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg) translate(0, -50%);
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes swing-in-top-fwd-t {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes spinner-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// FADE IN
@keyframes slide-in-bottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes fade-in-bottom-opacity {
  0% {
    transform: translate(-50%, 50%);
    opacity: 0;
  }

  70% {
    transform: translate(-50%, 0);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

// FADE OUT
@keyframes slide-out-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}


// SCALE X
@keyframes scale-x {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes scale-x-out {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

@keyframes animation-info {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animation-info-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}