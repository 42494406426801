@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.gallery {
  margin: 10px 0;
  text-align: center;
  width: 100%;

  &--container {
    max-width: 570px;
    text-align: left;
    width: 100%;
  }

  .slider {
    width: 100%;

    .slick {
      &-dots {
        bottom: -35px;
        margin: 0;
        left: 50%;
        padding: 0;
        position: absolute;
        transform: translate(-50%, 0);

        > li {
          background: transparent;
          margin: 0 5px;
          float: left;

          button {
            @include rem('font-size', 0px);
            border: 1px solid $blue-900;
            background-color: $white-900;
            height: 13px;
            float: left;
            padding: 0;
            width: 13px;
          }

          &.slick-active {
            button {
              background-color: $blue-900;
            }
          }
        }
      }

      &-list,
      &-track { 
        touch-action: pan-y;
      }
  
      &-list {
        float: left;
        padding: 0;
        overflow: hidden;
        width: 100%;
      }
  
      &-slide {
        align-items: flex-start;
        display: inline-flex;
        justify-content: center;
        
        > div {
          width: 100%;
        }

        &:nth-child(2n+2) {
          .link {
            background-color: $red-900;
          }
        }

        &:nth-child(3n+3) {
          .link {
            background-color: $blue-700;
          }
        }
      }
  
      &-slider {
        float: left;
        width: 100%;
      }
  
      &-track {
        float: left;
        width: 100%;
        vertical-align: top;
      }
    }
  }
}