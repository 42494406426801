/* --- COLORS --- */
$black-100: #707070;
$black-800: #202020;
$black-900: #000000;

$blue-300: #2DB5E6;
$blue-700: #3A5E9E;
$blue-900: #0056A1;
$blue-950: #3F5B70;

$gray-10: #BCC6D1;
$gray-50: #D8E0E8;
$gray-100: #DEDEDE;
$gray-200: #B4B4B6;
$gray-300: #EBE8E3;
$gray-400: #D6D1C7;
$gray-500: #E0E0E0;
$gray-600: #4F4F4F;
$gray-700: #4E4E4E;

$green-400: #BCCF00;

$orange-900: #F28B00;

$red-900: #E9533F;

$white-900: #FFFFFF;

$color_text: $gray-700;
