@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.news-item-info {
  float: left;
  padding: 0 0 70px;
  width: 100%;

  &--description,
  &--year {
    float: left;
    width: 100%;
  }

  &--description {
    padding: 0;

    .description,
    .title {
      margin: 0;
      width: 100%;
    }

    .description {
      @include font($font-default, 17px, 400, $gray-700);
      line-height: 1.5em;
      overflow: hidden;
      white-space: pre-line;
    }

    .link {
      background-color: $green-400;
      border-radius: 3px 3px 0px 0px;
      bottom: -95px;
      right: 0px;
      padding: 15px 15px 15px 35px;
      position: absolute;

      .icon {
        @include rem('font-size', 14px);
        color: $blue-950;
        left: 10px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }

      .text {
        @include font($font-default, 14px, 400, $blue-950);
        float: left;
        line-height: 1em;
      }
    }

    .title {
      @include font($font-default, 20px, 700, $blue-950);
      margin: 5px 0 10px;
    }
  }

  &--year {
    padding: 0;

    .text {
      @include font($font-default, 14px, 400, $gray-700);
      float: left;
      margin: 0;
      width: 100%;
    }
  }
}