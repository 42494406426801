@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.submenu-menu {
  max-width: 385px;
  width: 100%;

  &--item,
  &--list {
    width: 100%;
  }

  &--item {
    &--list {
      margin: 0;
      padding: 0 0 0 20px;
      width: 100%;

      &--item {
        margin: 0;
        width: 100%;
      }
    }
  }

  &--link {
    @include font($font-default, 16px, 600, $blue-950);
    background-color: transparent;
    border: none;
    box-shadow: none;
    line-height: 1em;
    outline: none;
    padding: 8px 20px;
    text-align: left;
    text-decoration: none;
    width: 100%;

    &--submenu {
      @include font($font-default, 14px, 400, $gray-600);
      padding: 6px 20px;
      text-decoration: none;
      width: 100%;

      &.active {
        background-color: $gray-300;
      }
    }

    &.active {
      background-color: $gray-300;
    }
  }

  &--list {
    margin: 0;
    padding: 0 20px;
  }

  &:before {
    background: $green-400;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 5px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    max-width: none;
    margin: 0 0 60px;

    .submenu-menu {
      &--list {
        padding: 0;
      }
    }

    &:before {
      left: -20px;
    }
  }
}