@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-block {
  width: 100%;

  &--title {
    @include font($font-default, 22px, 400, $black-100);
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 0 5px;
    width: 100%;
  }
}