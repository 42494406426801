@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.footer {
  background-color: $gray-300;
  margin: 190px 0 0;
  padding: 40px 0 70px;
  width: 100%;

  &--copy,
  &--company {
    p {
      @include font($font-default, 13px, 400, $gray-700);
      line-height: 1.5em;
    }

    a {
      @include font($font-default, 13px, 400, $gray-700);
      line-height: 1.5em;
      text-decoration: none;
    }
  }

  &--container {
    float: left;
    width: 100%;
  }

  &--copy {
    display: inline-flex;
    align-items: center;
    align-content: unset;
    flex-flow: row wrap;
    justify-content: flex-end;
    float: right;
    text-align: right;
    width: 50%;

    img {
      margin: -20px 0 0 35px;
    }

    p {
      margin-top: 0;
    }
  }

  &--company {
    width: 150px;

    a {
      font-weight: 600;
    }

    img {
      display: block;
      margin: -20px 0 0;
      mix-blend-mode: multiply;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    margin: 60px 0 0;
    
    .footer {
      &--copy,
      &--company {
        text-align: center;
        width: 100%;
      }

      &--copy {
        display: inline-block;
        margin: 20px 0 0;

        img {
          float: none;
          margin: 10px 0 0;
        }
      }
    }
  }
}