@import '../../../../styles/settings/settings';
@import '../../../../styles/tools/mixins';

.input-label {
  float: left;
  margin: 10px 0;
  position: relative;
  width: 100%;

  .label {
    @include font($font-default, 14px, 600, $gray-600);
    float: left;
    pointer-events: none;
    padding: 12px 0 0;
    transition: all 500ms cubic-bezier(0.4,0.35,0.25,1);
    width: 85px;
    z-index: 2;
  }

  .input-select,
  input,
  textarea {
    @include font($font-default, 14px, 400, $color_text);
    background: $white-900;
    border: 1px solid $gray-50;
    border-radius: 3px;
    float: left;
    outline: none;
    padding: 10px;
    width: calc(100% - 85px);
    z-index: 1;
  }

  textarea {
    height: auto;
    padding-top: 15px;
    resize: none;
  }

  &[data-error="true"] {
    textarea,
    input {
      border-color: $red-900;
      color: $color_text;
    }
  }

  input {
    &:required[value=""] {
      border-color: $gray-50;
    }
  }

  &[data-required="true"] {
    .label {
      &:after {
        content: '*';
      }
    }
  }

  &[data-type="check"] {
    display: inline-flex;
    pointer-events: none;
    width: auto;

    input {
      cursor: pointer;
      height: 100%;
      left: 0;
      margin: 0;
      opacity: 0;
      pointer-events: auto;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;

      & + .icon {
        float: left;
        height: 16px;
        position: relative;
        width: 16px;
        vertical-align: top;

        &:before,
        &:after {
          left: 0;
          position: absolute;
        }

        &:before {
          border: solid $color_text;
          border-width: 0 2px 2px 0;
          content: '';
          display: none;
          height: 8px;
          left: 6px;
          top: 0;
          transform: rotate(45deg) translate(2px, 2px);
          width: 4px;
          z-index: 1;
        }
        
        &:after {
          background-color: $white-900;
          border: 1px solid $gray-50;
          border-radius: 3px;
          height: 100%;
          content: '';
          left: 0;
          top: 0;
          width: 100%;
        }
      }

      &:checked + .icon {
        &:before {
          display: inline-block;
        }
      }
    }

    .label {
      display: none;
    }
  }

  &[data-type="select"] {
    .input-select {
      border: none;
      padding: 0;
      z-index: 9999;

      .select-title {
        height: 40px;
      }
    }
  }
}