.header-menu {
  text-align: right;
  width: 100%;

  &--home {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
    float: left;
    height: 85px;

    img {
      width: 117px;
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    height: 100px;

    .header-menu {
      &--home {
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        img {
          width: 130px;
        }
      }
    }
  }
}