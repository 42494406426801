@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.infographic-item {
  width: 50%;

  &--description {
    align-items: center;
    align-content: center;
    background-color: $green-400;
    bottom: 0;
    display: inline-flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    min-height: 178px;
    padding: 35px 25px 25px;
    position: absolute;
    text-decoration: none;
    width: 208px;

    .link {
      bottom: 15px;
      right: 10px;
      padding: 0;
      position: absolute;

      .icon {
        @include rem('font-size', 17px);
        color: $blue-950;
      }
    }

    .text {
      @include font($font-default, 18px, 600, $blue-950);
      margin: 0 0 10px;
      width: 100%;
    }
  }

  &--image {
    margin: 10px 0 0;
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    padding: 20px 25px;

    .infographic-item {
      &--description {
        bottom: -40px;
        left: 50%;
        min-height: 110px;
        padding: 20px 40px 20px 20px;
        transform: translate(-50%, 0);
        width: calc(100% - 70px);

        .text {
          margin-bottom: 0px;
        }
      }

      &--image {
        height: 420px;
        overflow: hidden;
        margin: 30px 0 0;
        width: 100%;

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 640px) {
    padding: 20px 5px;

    .infographic-item {
      &--description {
        width: calc(100% - 10px);
      }

      &--image {
        padding: 0 15px;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    margin: 60px 0;
    padding: 0;
    width: 100%;

    .infographic-item {
      &--description {
        width: 295px;
      }

      &--image {
        height: auto;
        text-align: center;

        img {
          float: none;
          height: auto;
          width: auto;
        }
      }
    }
  }
}