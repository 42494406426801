@import '../../../styles/components/animations';

@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.gallery-videos-item {
  background-color: transparent;
  cursor: pointer;
  display: none;
  height: 100%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  pointer-events: auto;
  top: 0;
  width: 685px;
  will-change: transform;
  user-select: auto;

  &--bg {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &[data-active="true"] {
      opacity: 0;
    }
  }

  &--content {
    left: 0;
    height: 100%;
    min-height: 350px;
    position: absolute;
    top: 0;
    width: 100%; 
    z-index: 1;
  }

  &--play {
    background-color: transparent;
    border: none;
    box-shadow: none;
    height: 100%;
    left: 0;
    padding: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 100%;

    .icon {
      @include rem('font-size', 41px);
      color: $black-800;
      left: calc(50% + 5px);
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      background-color: $gray-200;
      border-radius: 100%;
      content: '';
      height: 114px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 114px;
    }
  }

  &--title {
    align-items: center;
    background-color: $blue-950;
    display: none;
    justify-content: center;
    height: 40px;
    text-align: center;
    top: -40px;
    width: 100%;
    z-index: 6;

    .text {
      @include font($font-default, 17px, 500, $white-900);
      margin: 0;
      width: 100%;
    }
  }

  &[data-active="true"] {
    display: inline-block;
    z-index: 2;

    .gallery-videos-item {
      &--title {
        display: inline-flex;
      }
    }

    &[data-dir="next"] {
      animation: ani-next-center 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }

    &[data-dir="prev"] {
      animation: ani-prev-center 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }

    &[data-dir="none"] {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &[data-next="true"],
  &[data-prev="true"] {
    display: inline-block;
    z-index: 1;
  }

  &[data-next="true"] {
    &[data-dir="next"] {
      animation: ani-next-next 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }

    &[data-dir="none"] {
      left: initial;
      right: -6.6%;
      transform: translate(0, 0) scale(0.81);
    }
  }

  &[data-prev="true"] {
    display: none;
    &[data-dir="none"] {
      left: -6.6%;
      right: initial;
      transform: translate(0, 0) scale(0.81);
    }
  }
}

@keyframes ani-next-center {
  0% {
    opacity: 0;
    transform: translate(100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes ani-prev-center {
  0% {
    opacity: 0;
    transform: translate(-100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes ani-next-next {
  0% {
    left: initial;
    opacity: 0;
    right: -6.6%;
    transform: translate(100%, 0) scale(0.81);
  }
  100% {
    left: initial;
    opacity: 1;
    right: -6.6%;
    transform: translate(0, 0) scale(0.81);
  }
}

@keyframes ani-next-prev {
  0% {
    left: -6.6%;
    opacity: 0;
    right: initial;
    transform: translate(-100%, 0) scale(0.81);
  }
  100% {
    left: -6.6%;
    opacity: 1;
    right: initial;
    transform: translate(0, 0) scale(0.81);
  }
}

@keyframes ani-prev {
  0% {
    left: 0;
    opacity: 0;
    right: initial;
    transform: translate(-100%, 0) scale(0.81);
  }
  100% {
    left: -6.6%;
    opacity: 1;
    right: initial;
    transform: translate(0, 0) scale(0.81);
  }
}