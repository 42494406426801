/* --- ICONS --- */
%icon,
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-strong:before {
  content: "\e900";
}
.icon-arrow:before {
  content: "\e901";
}
.icon-burguer:before {
  content: "\e902";
}
.icon-certification:before {
  content: "\e903";
}
.icon-config:before {
  content: "\e904";
}
.icon-down:before {
  content: "\e905";
}
.icon-facebook:before {
  content: "\e906";
}
.icon-flower:before {
  content: "\e907";
}
.icon-graph:before {
  content: "\e908";
}
.icon-handshake:before {
  content: "\e909";
}
.icon-hourglass:before {
  content: "\e90a";
}
.icon-increment:before {
  content: "\e90b";
}
.icon-inv-up:before {
  content: "\e90c";
}
.icon-list:before {
  content: "\e90d";
}
.icon-lock:before {
  content: "\e90e";
}
.icon-mail:before {
  content: "\e90f";
}
.icon-msm:before {
  content: "\e910";
}
.icon-people:before {
  content: "\e911";
}
.icon-phone:before {
  content: "\e912";
}
.icon-picker:before {
  content: "\e913";
}
.icon-pie:before {
  content: "\e914";
}
.icon-play:before {
  content: "\e915";
}
.icon-plus:before {
  content: "\e916";
}
.icon-print:before {
  content: "\e917";
}
.icon-profile:before {
  content: "\e918";
}
.icon-quality:before {
  content: "\e919";
}
.icon-search:before {
  content: "\e91a";
}
.icon-time:before {
  content: "\e91b";
}
.icon-times:before {
  content: "\e91c";
}
