.page-submenu {
  margin: 60px 0;
  width: 100%;

  &--container {
    width: calc(100% - 385px);
  }

  &--main {
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .page-submenu {
      &--container {
        width: 100%;
      }
    }
  }
}