@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.news-item-min {
  width: 100%;

  &--image {
    height: 120px;
    padding: 0 0 0 15px;
    width: 170px;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &:before {
      background-color: $green-400;
      content: '';
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 7px;
    }
  }

  &--info {
    padding: 0 0 0 10px;
    width: calc(100% - 185px);
  
    .date,
    .intro,
    .title {
      margin: 0;
      width: 100%;
    }

    .date {
      @include font($font-default, 12px, 600, $gray-700);
    }

    .intro {
      @include font($font-default, 16px, 400, $gray-700);
      line-height: 1.4em;
    }

    .title {
      @include font($font-default, 20px, 600, $blue-950);
      margin: 0 0 15px;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .news-item-min {
      &--image,
      &--info {
        width: 100%;
      }

      &--info {
        margin: 20px 0 0;
      }
    }
  }
}