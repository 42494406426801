@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-block-item {
  background: $white-900;
  border: 1px solid $gray-50;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  margin: 0 0 20px;
  padding: 30px;
  width: 100%;

  &--container {
    padding: 0 0 0 50px;
    width: 100%;

    .description,
    .title {
      margin: 0;
      width: 100%;
    }

    .description {
      @include font($font-default, 15px, 400, $gray-700);
      line-height: 1.5em;
      white-space: pre-line;
    }

    .title {
      @include font($font-default, 18px, 600, $blue-950);
      margin: 0 0 5px;
      text-transform: uppercase;
    }
  }

  &--list {
    margin: 30px 0 0;
    list-style: none;
    padding: 0;
    width: 100%;

    &--item {
      @include font($font-default, 15px, 400, $gray-700);
      margin: 0 0 30px;
      padding: 0 0 0 10px;
      width: 100%;

      p {
        margin: 0;
        line-height: 1.7em;
        width: 100%;
        white-space: pre-line;

        strong {
          font-weight: 800;
        }
      }

      &:before {
        @include font($font-default, 18px, 800, $gray-700);
        content: '>';
        left: 0;
        position: absolute;
        top: -1px;
      }
    }
  }

  .footer-description {
    @include font($font-default, 15px, 400, $gray-700);
    margin: 0;
    line-height: 1.7em;
    padding: 0 0 0 10px;
    width: 100%;

    a {
      @include font($font-default, 15px, 800, $blue-950);
    }
  }

  .number {
    @include font($font-default, 53px, 600, $blue-950);
    left: 25px;
    margin: 0;
    line-height: 1em;
    position: absolute;
    top: 25px;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    padding: 20px;

    .list-block-item {
      &--container {
        margin-top: 50px;
        padding: 0;

        .description {
          @include rem('font-size', 14px);
        }

        .title {
          @include rem('font-size', 16px);
        }
      }
    }

    .footer-description {
      @include rem('font-size', 14px);

      a {
        @include rem('font-size', 14px);
      }
    }

    .number {
      @include rem('font-size', 40px);
      left: 20px;
      top: 15px;
    }
  }
}