/* --- GRID --- */
@import './colors';

html,
body,
body * {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

html,
body {
  float: left;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  vertical-align: top;
}

html {
  overflow: hidden;
  overflow-y: auto;
}

body {
  height: auto;
  font-family: 'Titillium Web', 'sans-serif';
  min-height: 100vh;
  overflow: hidden;
  overflow-anchor: none;
  width: 100vw;

  .main {
    float: left;
    width: 100vw;
  }

  script,
  style,
  link {
    display: none;
  }
}

#root {
  min-height: 100vh;
  width: 100%;
}

#search {
  &:empty {
    display: none;
  }
}