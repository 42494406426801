@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-grid-icon-item {
  padding: 0 20px;
  width: 100%;

  &--description,
  &--header {
    margin: 0;
    width: 100%;
  }

  &--description {
    @include font($font-default, 17px, 400, $gray-600);
    line-height: 1.4em;
    margin: 20px 0 0;
    text-align: center;
  }

  &--header {
    text-align: center;

    .icon {
      @include rem('font-size', 83px);
      color: $green-400;
    }

    .title {
      @include font($font-default, 24px, 400, $gray-600);
      margin: 20px 0 0;
      text-align: center;
    }
  }
}