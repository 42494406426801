.image-component {
  width: 100%;

  &[data-type="left"] {
    text-align: left;

    img {
      width: auto;
    }
  }

  &[data-type="center"] {
    text-align: center;
  }

  &[data-type="full"] {
    img {
      width: 100%;
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}