.content {
  width: 100%; 
  
  &--body {
    display: grid;
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
    width: 1210px;
  }

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1240px) {
    padding: 0 20px;

    .content {
      &--body {
        display: inline-block;
        width: 100%;
      }
    }
  }
}