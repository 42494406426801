@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

.gallery-control {
  background-color: $green-400;
  border: none;
  height: 85px;
  left: 0;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 40px;
  z-index: 2;

  .icon {
    color: $blue-950;
  }

  &.left {
    .icon {
      transform: rotate(-180deg);
    }
  }

  &.right {
    left: initial;
    right: 0;
  }
}