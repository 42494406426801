@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-grid-links-item {
  width: 100%;

  .link {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    border: none;
    border-top: 3px solid $green-400;
    bottom: 10px;
    height: 100px;
    left: 60px;
    padding: 15px;
    position: absolute;
    width: calc(100% - 120px);

    .subtext,
    .text {
      width: 100%;
    }

    .icon {
      @include rem('font-size', 13px);
      background-color: $green-400;
      border-radius: 100%;
      color: $blue-950;
      height: 34px;
      right: 15px;
      position: absolute;
      top: 15px;
      width: 34px;

      &:before {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .subtext {
      @include font($font-default, 13px, 400, $white-900);
    }

    .text {
      @include font($font-default, 22px, 600, $white-900);
      line-height: 1.2em;
      padding: 0 50px 0 0;
    }
  }

  img {
    width: 100%;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .link {
      left: 20px;
      width: calc(100% - 40px);
    }

    img {
      max-height: 170px;
    }
  }
}