@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.reports-tabs {
  width: 100%;

  &--controls {
    width: 100%;

    .tab {
      @include font($font-default, 14px, 600, $blue-950);
      background-color: $white-900;
      border: 1px solid $gray-300;
      border-bottom: none;
      box-shadow: none;
      opacity: 0.5;
      line-height: 1em;
      padding: 16px 15px;
      text-align: center;
      text-transform: uppercase;

      &:before,
      &:after {
        content: '';
        position: absolute;
      }

      &:before {
        background-color: $blue-950;
        height: 2px;
        left: 0;
        top: 0;
        width: 100%;
      }

      &:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $blue-950;
        height: 0;
        left: 50%;
        transform: translate(-50%, 0);
        top: 0;
        width: 0;
      }

      &:last-child {
        border-left: none;
      }

      &[data-active="true"] {
        opacity: 1;
      }
    }
  }

  &--report,
  &--others {
    display: none;
    width: 100%;

    &[data-active="true"] {
      display: inline-block;
    }
  }

  &--report {
    padding: 30px 0;

    .description {
      @include font($font-default, 17px, 400, $color_text);
      line-height: 1.4em;
      margin: 0 0 30px;
      width: 100%;
      white-space: pre-line;
    }
  }

  &--others {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px 10px;
    padding: 30px 0;

    .reports-item {
      align-items: center;
      align-content: space-between;
      display: inline-flex;
      flex-flow: row wrap;
    }
    
    &[data-active="true"] {
      display: grid;
    }
  }

  &[data-active="true"] {
    display: inline-block;
  }

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1300px) {
    .reports-tabs {
      &--others {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  /* Medium Devices, Desktops */
  @media only screen and (max-width : 1024px) {
    .reports-tabs {
      &--others {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    width: 100%;
    
    .reports-tabs {
      &--controls {
        .tab {
          width: 50%;
        }
      }

      &--others {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    .reports-tabs {
      &--others {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}