@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.pagination-control {
  background: $gray-50;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.13);
  height: 40px;
  padding: 0 15px;
  width: 110px;

  .icon {
    @include rem('font-size', 13px);
    color: $orange-900;
    left: 15px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  .text {
    @include font($font-default, 13px, 600, $blue-950);
    margin: 1px 0 -1px;
    line-height: 1em;
    text-transform: uppercase;
    text-align: right;
    width: 100%;
  }

  &[data-type="prev"] {
    .icon {
      left: initial;
      right: 15px;
      transform: translate(0, -50%) rotate(180deg);
    }

    .text {
      text-align: left;
    }
  }

  &[data-disabled="true"] {
    opacity: 0;
    pointer-events: none;
  }
}