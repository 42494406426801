@import '../../styles/settings/colors';
@import '../../styles/settings/fonts';

@import '../../styles/tools/mixins';

.intro {
  width: 100%;

  &--logo {
    margin: 0 0 20px;
    width: 100%;
  }

  &--info {
    width: 100%;

    &--image {
      margin: 0 15px 0 0;
      width: calc(50% - 15px);

      .label {
        @include font($font-default, 14px, 400, $gray-600);
        background-color: rgba($green-400, 0.9);
        bottom: -25px;
        left: 20px;
        padding: 20px;
        position: absolute;
        width: calc(100% - 40px);

        &:empty {
          display: none;
        }
      }

      img {
        width: 100%;
      }
    }

    .text {
      @include font($font-default, 17px, 400, $gray-600);
      float: right;
      margin: 0;
      line-height: 1.4em;
      padding: 0 0 0 20px;
      width: 50%;
      white-space: pre-line;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .intro {
      &--info {
        &--image {
          width: 100%;
        }

        .text {
          margin: 60px 0 0;
          width: 100%;
        }
      }
    }
  }
}