.page-interna {
  width: 100%;
  
  &.clear {
    padding: 120px 0 0;
    
    .list-grid-links {
      margin-top: 20px;
    }
  }
}