@import '../../../styles/components/animations';

@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.webdoor-item {
  background-color: $blue-300;
  cursor: pointer;
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: auto;
  width: 100%;
  top: 0;
  will-change: transform;
  user-select: auto;
  z-index: 2;

  &--content {
    left: 0;
    position: absolute;
    top: calc(50% + 20px);
    transform: translate(0, -50%);
    width: 505px;
    z-index: 3;

    .description,
    .title {
      float: left;
      line-height: 1em;
      text-transform: uppercase;
      width: 100%;
    }

    .description {
      @include font($font-default, 24px, 700, $white-900);
      margin: 0 0 5px;
    }

    .title {
      @include font($font-default, 50px, 700, $white-900);
    }
  }

  .content {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .link {
    float: left;
    height: 100%;
    text-decoration: none;
    width: 100%;
    
    img {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      float: left;
      height: 100%;
      object-fit: cover;
      position: relative;
      pointer-events: none;
      width: 100% !important;
      vertical-align: top;
    }
  }

  &:before {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 103.24%);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
    z-index: 3;
  }

  &[data-active="true"] {
    display: inline-block;

    &[data-dir="next"] {
      animation: slide-in-right 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }

    &[data-dir="prev"] {
      animation: slide-in-left 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }
  }

  &[data-last="true"] {
    display: inline-block;

    &[data-dir="next"] {
      animation: slide-out-left 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }

    &[data-dir="prev"] {
      animation: slide-out-right 0.6s cubic-bezier(0.38, 0.4, 0.58, 1) both;
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 640px) {
    height: 100%;

    .webdoor-item {
      &--content {
        padding: 20px 50px;
        position: relative;
        top: inherit;
        transform: none;
        width: 100%;

        .description,
        .title {
          color: $white-900;
          z-index: 2;
        }

        .description {
          @include rem('font-size', 20px);
          font-weight: 300;
        }

        .title {
          @include rem('font-size', 26px);
        }
      }
    }

    .content {
      bottom: 0;
      height: auto;
      left: 0;
      padding: 0;
      position: absolute;
      top: initial;
    }

    .link {
      height: 100%;
      z-index: 2;
    }

    &:before {
      display: none;
    }
  }
}
