@import '../../settings/colors';

.btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-family: $font-default;
  outline: none;

  &:disabled {
    pointer-events: none;
  }
}

.btn-default {
  background-color: $red-900;
}