@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-grid-collapse {
  margin: 20px 0;
  width: 100%;

  &--item {
    width: 100%;
  }
  
  &--container {
    column-count: 2;
    column-gap: 25px;
    margin: 0;
    list-style: none;
    padding: 0;
    width: 100%;
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .list-grid-collapse {
      &--container {
        column-count: 1;
      }
    }
  }
}