@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.tabs-item {
  margin: 40px 0;
  width: 100%;

  &--item,
  &--list {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &--item {
    background-color: $white-900;
    border: 1px solid $gray-300;
    border-radius: 3px;
    filter: drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.13));
    margin: 0;
    
    .download {
      background-color: $blue-950;
      border-radius: 3px;
      box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.13);
      outline: none;
      margin: 10px;
      padding: 12px 15px 10px 30px;
      
      .icon {
        @include rem('font-size', 15px);
        color: $white-900;
        left: 8px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }

      .text {
        @include font($font-default, 13px, 400, $white-900);
        list-style: 1em;
        text-transform: uppercase;
      }
    }

    .image {
      width: 100%;
    }

    .title {
      @include font($font-default, 17px, 600, $gray-700);
      padding: 0 10px;
    }
  }

  &--list {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .tabs-item {
      &--list {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  /* Custom, iPhone Retina */ 
  @media only screen and (max-width : 375px) {
    .tabs-item {
      &--list {
        grid-template-columns: 1fr;
      }
    }
  }
}