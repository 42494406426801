/* --- MIXINS --- */
@import '../settings/colors';

/* --- FONT --- */
@mixin font($font: $font-default, $size: 12px, $weight: 400, $color: $purple_dark) {
  @include rem('font-size', $size);
  color: $color;
  font-family: $font;
  font-weight: $weight;
}

/* --- REM --- */
@mixin rem($property, $px_values) {
  $baseline_rem: ($font-base-size / 1rem);
  #{$property}: $px_values;

  // If there is only one (numeric) value, return the property/value line for it.
  @if type-of($px_values) == 'number' {
    #{$property}: $px_values / $baseline_rem;
  }
  @else {
    $rem_values: ();

    @each $value in $px_values {
      @if $value == 0 {
        $rem_values: append($rem_values, $value);
      } @else {
        $rem_values: append($rem_values, ($value / $baseline_rem));
      }

    }

    // Return the property and its list of converted values
    #{$property}: $rem_values;
  }
}

/* --- SUPPORT HOVER --- */
@mixin hover-supported {
  @media not all and (hover: none) {
    &:hover {
      @content;
    }
  }
}

/* --- SCROLL --- */
@mixin scrollV($width: 6px, $bg-color: $white, $bg-bar-color: $color_text) {
  &::-webkit-scrollbar {
    width: $width;
  }
  
  &::-webkit-scrollbar-track {
    background: $bg-color;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: $bg-bar-color;
  }

  scrollbar-width: thin;
  scrollbar-color: $bg-bar-color transparent;
}
