@import '../../../../styles/settings/colors';
@import '../../../../styles/settings/fonts';

@import '../../../../styles/tools/mixins';

.list-grid-file-item {
  border: 1px solid $gray-300;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  padding: 25px;
  width: 100%;

  .link,
  .title {
    width: 100%;
  }

  .link {
    padding: 10px 0 10px 30px;
    
    .icon {
      @include rem('font-size', 17px);
      color: $blue-950;
      left: 0;
      position: absolute;
      top: 10px;
    }

    .text {
      @include font($font-default, 15px, 400, $gray-700);
      margin: 0;
      width: 100%;
    }
  }

  .title {
    @include font($font-default, 20px, 600, $blue-950);
    margin: 0 0 10px;
  }
}