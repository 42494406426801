@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.reports-item {  
  align-items: center;
  background: $white-900;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.13);
  border-radius: 3px;
  display: inline-flex;
  justify-content: flex-start;
  margin: 0;
  padding: 30px;
  width: 100%;

  &--image {
    width: 180px;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &--info {
    padding: 30px;

    .download {
      background-color: $blue-950;
      border: none;
      border-radius: 3px;
      padding: 0;
      
      .icon {
        @include rem('font-size', 17px);
        color: $white-900;
        left: 10px;
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
      }

      .text {
        @include font($font-default, 13px, 400, $white-900);
        line-height: 1em;
        margin: 0;
        padding: 17px 15px 14px 40px;
      }
    }

    .title {
      @include font($font-default, 30px, 400, $blue-950);
      margin: 0 0 20px;
    }
  }

  &[data-type="2"] {
    display: inline-block;
    padding: 15px 10px;

    .reports-item {
      &--image {
        text-align: center;
        width: 100%;

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      &--info {
        padding: 20px 5px 0;
        text-align: center;
        width: 100%;

        .download {
          float: none;
        }

        .title {
          @include rem('font-size', 17px);
          text-align: center;
        }
      }
    }
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width : 768px) {
    .reports-item {
      &--info {
        .title {
          @include rem('font-size', 26px);
        }
      }
    }
  }

  /* Extra Small Devices, Phones */ 
  @media only screen and (max-width : 480px) {
    .reports-item {
      &--image {
        width: 400px;
      }

      &--info {
        padding-right: 0;
      }
    }
  }
}