@import '../../../styles/settings/colors';
@import '../../../styles/settings/fonts';

@import '../../../styles/tools/mixins';

.list-news-control-button {
  height: 40px;
  position: absolute;
  top: calc(50% - 60px);
  transform: translate(0, -50%);
  width: 40px;
  z-index: 2;

  .icon {
    @include rem('font-size', 16px);
    color: $white-900;
    transition: all 400ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }

  &.left {
    left: -60px;

    .icon {
      transform: rotate(180deg);
    }
  }

  &.right {
    right: -60px;
  }

  &:before {
    background-color: $blue-950;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &[data-disabled="true"] {
    .icon {
      color: $blue-950;
    }

    &:before {
      background-color: $green-400;
    }
  }

  /* Large Devices, Wide Screens */
  @media only screen and (max-width : 1240px) {
    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}